import { computed } from 'vue';
import { useSdk } from './use-sdk';
import { storeToRefs, defineStore } from 'pinia';

function parseOnboardingFields (dotNotation) {
  const keys = dotNotation.split('.');
  const result = {};

  let currentObj = result;

  for (let i = 0; i < keys.length - 1; i++) {
    currentObj[keys[i]] = {};
    currentObj = currentObj[keys[i]];
  }

  // Set the innermost field as undefined
  currentObj[keys[keys.length - 1]] = Date.now();

  return result;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    initialized: false,
    currentUser: null,
  }),
  actions: {
    async init () {
      const sdk = useSdk();
      this.currentUser = await sdk.currentUser();
      this.initialized = true;
    },
    async signin (data) {
      const sdk = useSdk();
      const res = await sdk.signinWithEmail(data.email, data.password, {
        remoteApiUrl: data.remoteApiUrl,
        remoteSyncForce: data.remoteSyncForce,
      });
      this.currentUser = await sdk.currentUser();
      this.initialized = true;
      return res;
    },
    async signout () {
      const sdk = useSdk();
      await sdk.signout();
      this.currentUser = await sdk.currentUser();
      this.initialized = false;
    },
  },
});

export function useAuth () {
  const { signin, signout, init, currentUser } = useAuthStore();
  const sdk = useSdk();
  const SERVICE_NAME = 'authentication';
  const ACCOUNTS_SERVICE_NAME = 'accounts';

  async function signinWithToken (token) {
    return sdk.signinWithToken(token);
  }
  const getToken = () => sdk.token();
  const register = async (data, skipSignIn = true) => {
    await sdk.create('accounts', data);
    if (skipSignIn) return;
    const { email, password } = data;
    await signout();
    const account = await signin({ email, password });
    init();
    return account;
  };
  const accountExists = async (email) => {
    const result = await sdk.create(SERVICE_NAME, {
      action: 'checkUniqueIdentity',
      identityKey: 'email',
      identity: email,
    });
    const isUnique = result?.unique;
    return !isUnique;
  };
  const sendVerificationEmail = async (opts) => {
    const payload = Object.assign({
      action: 'sendVerificationEmail',
    }, opts);
    return sdk.create(SERVICE_NAME, payload);
  };
  const applyActionCode = async (opts) => {
    const payload = Object.assign({
      action: 'applyActionCode',
    }, opts);
    return sdk.create(SERVICE_NAME, payload);
  };
  const updateOnboarding = async (field) => {
    const uid = currentUser?.uid;

    await sdk.update(ACCOUNTS_SERVICE_NAME, uid, {
      onboarding: {
        ...parseOnboardingFields(field),
      },
    });

    init();
  }

  const doctorProfessionalDetailsOnboardingDone = computed(() => {
    return !!currentUser?.onboarding?.doctorOnboarding?.professionalDetails;
  });

  const doctorPersonalDetailsOnboardingDone = computed(() => {
    return !!currentUser?.onboarding?.doctorOnboarding?.personalDetails;
  });

  const doctorWelcomeOnboardingDone = computed(() => {
    return !!currentUser?.onboarding?.doctorOnboarding?.welcome;
  });

  const doctorOnboardingDone = computed(() => {
    return (
      doctorProfessionalDetailsOnboardingDone.value &&
      doctorPersonalDetailsOnboardingDone.value
    );
  });

  return {
    ...storeToRefs(useAuthStore()),
    accountExists,
    applyActionCode,
    getToken,
    init,
    register,
    sendVerificationEmail,
    signin,
    signinWithToken,
    signout,
    updateOnboarding,
    doctorProfessionalDetailsOnboardingDone,
    doctorPersonalDetailsOnboardingDone,
    doctorWelcomeOnboardingDone,
    doctorOnboardingDone,
  };
}

export function useCurrentUser () {
  return useAuth().currentUser;
}

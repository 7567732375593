import { reactive, toRefs } from 'vue';
import { STORAGE_KEY_ACTIVE_FACILITY } from '../constants/sdk';
import { useSdk } from '@/composables/use-sdk';
import { useAuth } from '@/composables/use-auth';

const state = reactive({
  facilityDiscounts: [],
  schools: [],
  activeFacility: {},
  facilities: [],
});

export function useFacilities () {
  const sdk = useSdk();

  function setActiveFacility (facility) {
    if (!facility) return;
    globalThis.localStorage.setItem(STORAGE_KEY_ACTIVE_FACILITY, JSON.stringify(facility));
  }

  async function getActiveFacility (forcedFetch) {
    const facility = globalThis.localStorage.getItem(STORAGE_KEY_ACTIVE_FACILITY);

    if (facility && !forcedFetch) {
      const parsed = JSON.parse(facility);
      state.activeFacility = parsed;
      return parsed;
    }

    const { activeFacility } = await getUserFacilities();

    // TODO: implement fetching of licenses based on the active facility if on offline mode

    setActiveFacility(activeFacility);

    state.activeFacility = activeFacility;

    return activeFacility;
  }

  async function getUserFacilities (opts) {
    const { currentUser } = useAuth();
    const uid = opts?.uid || currentUser.value?.uid;
    const query = {
      uid,
      $populate: {
        facility: {
          service: 'organizations',
          method: 'get',
          localKey: 'organization',
          foreignKey: 'id',
        },
      },
      $sort: {
        id: 1,
      },
    };

    const res = await sdk.list('organization-members', query);
    
    const mapped = res?.data.map((item) => {
      return {
        ...item?.$populated?.facility,
        roles: item.roles,
      };
    });
    state.facilities = mapped;
    state.activeFacility = mapped?.[0];
    setActiveFacility(mapped?.[0]);
    return {
      facilities: mapped,
      activeFacility: mapped?.[0],
    };
  }

  async function updateItem (id, payload) {
    await sdk.update('organizations', id, payload);
    await getUserFacilities();
  }

  return {
    ...toRefs(state),
    setActiveFacility,
    getActiveFacility,
    getUserFacilities,
    updateItem,
  };
}

export function useGetSchools () {
  const sdk = useSdk();

  async function getList () {
    const res = await sdk.list('organizations', {
      type: 'school',
      $sort: {
        name: 1,
      },
    });
    state.schools = res.data;
  }

  return {
    ...toRefs(state),
    getList,
  };
}

export function useOrganizationConfigs () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function listConfigs (opts) {
    const facility = await getActiveFacility();
    const facilityId = facility?.id;
    const query = {
      id: facilityId,
      ...opts,
    };
    const res = await sdk.get('organizations', query);
    state.facilityDiscounts = res?.configBilling?.discountTypes || [];
  }

  const update = async (data) => {
    const facility = await getActiveFacility();
    const facilityId = facility?.id;
    if (!facilityId) return;
    const payload = {
      ...data,
    };
    const res = await sdk.update('organizations', facilityId, payload);
    return res;
  };

  return {
    ...toRefs(state),
    listConfigs,
    update,
  };
}

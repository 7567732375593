import validate from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/middleware/auth.global.js";
import manifest_45route_45rule from "/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@18.19.39_ioredis@5.4.1_magicast@0.3.4_rollup@4._jfdfwhiczt6wlp32domcty643u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  redirect: () => import("/home/runner/work/parmazip-pharmacist/parmazip-pharmacist/src/middleware/redirect.js")
}
import { defineNuxtRouteMiddleware } from '#imports';
import { useAuth } from '@/composables/use-auth';
import { useFacilities } from '@/composables/use-facilities';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return;
  // skip on splashscreen
  if (to.path === '/splashscreen') {
    console.warn('Skipping auth middleware on splash');
    return;
  }
  console.warn('running auth middleware');
  const { init } = useAuth();
  const { getActiveFacility } = useFacilities();
  await init();
  await getActiveFacility();
});
